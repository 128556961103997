/*
- Chocolat - lightbox plugin
- Swiper Slider
- color box
*/

/* Chocolat Lightbox */

.chocolat-zoomable.chocolat-zoomed {
    cursor: zoom-out;
}
.chocolat-open {
    overflow: hidden;
}
.chocolat-overlay {
    transition: opacity 0.4s ease, visibility 0s 0.4s ease;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: #000;
    visibility: hidden;
    opacity: 0;
}
.chocolat-overlay.chocolat-visible {
    transition: opacity 0.4s, visibility 0s;
    visibility: visible;
    opacity: 0.8;
}

.chocolat-wrapper {
    transition: opacity 0.4s ease, visibility 0s 0.4s ease;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 16;
    color: #fff;
    visibility: hidden;
}
.chocolat-wrapper.chocolat-visible {
    transition: opacity 0.4s, visibility 0s;
    opacity: 1;
    visibility: visible;
}

.chocolat-zoomable .chocolat-img {
    cursor: zoom-in;
}
.chocolat-loader {
    transition: opacity 0.3s;
    height: 32px;
    width: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
    z-index: 11;
    background:     "url("+'images/images/chocolat/loader.gif'+")";
    
    opacity: 0;
}
.chocolat-loader.chocolat-visible {
    opacity: 1;
}

.chocolat-image-wrapper {
    position: fixed;
    width: 0px;
    height: 0px;
    left: 50%;
    top: 50%;
    z-index: 14;
    text-align: left;
    transform: translate(-50%, -50%);
}

.chocolat-image-wrapper .chocolat-img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.chocolat-wrapper .chocolat-left {
    width: 50px;
    height: 100px;
    cursor: pointer;
    background: "url("+'images/images/chocolat/left.png'+")" 50% 50% no-repeat;
    z-index: 17;
    visibility: hidden;
}

.chocolat-layout {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.chocolat-image-canvas {
    transition: opacity .2s;
    opacity: 0;
    flex-grow: 1;
    align-self: stretch;
}
.chocolat-image-canvas.chocolat-visible {
    opacity: 1;
}
.chocolat-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.chocolat-wrapper .chocolat-right {
    width: 50px;
    height: 100px;
    cursor: pointer;
    background: "url("+'images/images/chocolat/right.png'+")" 50% 50% no-repeat;
    z-index: 17;
    visibility: hidden;
}
.chocolat-wrapper .chocolat-right.active {
    visibility: visible;
}
.chocolat-wrapper .chocolat-left.active {
    visibility: visible;
}
.chocolat-wrapper .chocolat-top {
    height: 50px;
    overflow: hidden;
    z-index: 17;
    flex-shrink: 0;
}
.chocolat-wrapper .chocolat-close {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background:"url("+'images/images/chocolat/close.png'+")" 50% 50% no-repeat;
}
.chocolat-wrapper .chocolat-bottom {
    height: 40px;
    font-size: 12px;
    z-index: 17;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(0, 0, 0, 0.2);
    flex-shrink: 0;
    display: flex;
    align-items: center;

}
.chocolat-wrapper .chocolat-set-title {
    display: inline-block;
    padding-right: 15px;
    line-height: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.chocolat-wrapper .chocolat-pagination {
    float: right;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
}
.chocolat-wrapper .chocolat-fullscreen {
    width: 16px;
    height: 40px;
    


    background: "url("+'images/images/chocolat/fullscreen.png'+")" 50% 50% no-repeat;
    display: block;
    cursor: pointer;
    float: right;
}
.chocolat-wrapper .chocolat-description {
    display: inline-block;
    flex-grow: 1;
    text-align: left;
}

/* no container mode*/
body.chocolat-open > .chocolat-overlay {
    z-index: 15;
}
body.chocolat-open > .chocolat-loader {
    z-index: 15;
}
body.chocolat-open > .chocolat-image-wrapper {
    z-index: 17;
}

/* container mode*/
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-image-wrapper,
.chocolat-in-container .chocolat-overlay {
    position: absolute;
}
.chocolat-in-container {
    position: relative;
}

.chocolat-zoomable.chocolat-zooming-in .chocolat-image-wrapper,
.chocolat-zoomable.chocolat-zooming-out .chocolat-image-wrapper {
    transition: width .2s ease, height .2s ease;
}
.chocolat-zoomable.chocolat-zooming-in .chocolat-img,
.chocolat-zoomable.chocolat-zooming-out .chocolat-img {
    transition: margin .2s ease;
}

/*--------------------------------------------------------------
/** Colorbox Lightbox
--------------------------------------------------------------*/
/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000;}
#colorbox{outline:0;}
#cboxContent{margin-top:20px;background:#000;}
.cboxIframe{background:#fff;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxLoadedContent{border:5px solid #000; background:#fff;}
#cboxTitle{position:absolute; top:-20px; left:0; color:#ccc;}
#cboxCurrent{position:absolute; top:-20px; right:0px; color:#ccc;}
#cboxLoadingGraphic{background: "url("+'images/images/colorbox/loading.gif'+")" no-repeat center center;}




/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

#cboxSlideshow{position:absolute; top:-20px; right:90px; color:#fff;}
#cboxPrevious{position:absolute; top:50%; left:5px; margin-top:-32px; background:"url("+'images/images/colorbox/controls.png'+")" no-repeat top left; width:28px; height:65px; text-indent:-9999px;}
#cboxPrevious:hover{background-position:bottom left;}
#cboxNext{position:absolute; top:50%; right:5px; margin-top:-32px; background:"url("+'images/images/colorbox/controls.png'+")" no-repeat top right; width:28px; height:65px; text-indent:-9999px;}
#cboxNext:hover{background-position:bottom right;}
#cboxClose{position:absolute; top:5px; right:5px; display:block; background:"url("+'images/images/colorbox/controls.png'+")" no-repeat top center; width:38px; height:19px; text-indent:-9999px;}
#cboxClose:hover{background-position:bottom center;}


